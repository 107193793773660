<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
        <label for="account_id">Account:</label>
        <Multiselect mode="multiple" required v-model="value_multiple_sales" :options="accountOptions" valueProp="valueProp" label="label" :searchable="true">
          <template v-slot:multiplelabel="{ values }">
            <div class="multiselect-multiple-label">
              <span v-for="(item2, index2) in values" :key="index2">{{item2.label}},&nbsp;&nbsp;</span>
            </div>
          </template>
        </Multiselect>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="sales_name">Sales Name:</label>
        <input class="form-control" name="sales_name" required type="text" v-model="sales.sales_name" maxlength="255">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="sales_code">Sales Code:</label>
        <input class="form-control" name="sales_code" required type="text" v-model="sales.sales_code" maxlength="50">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>        
        <select class="form-control" required name="status" v-model.number="sales.status">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/sales">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Multiselect from '@vueform/multiselect'
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('sales', ['sales', 'onRequest']),
    },
    data(){
      return {
        accountOptions: [],
        value_multiple_sales: null,
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        let res = []
        this.value_multiple_sales.map(item => {
          res.push(item)
        })
        this.sales.sales_accounts = res
        this.edit(this.sales)
      },
      ...mapActions('sales', ['edit', 'get']),
      ...mapActions('accounts', ['get_all']),
    },
    components:{
        Multiselect
    },
    created() {
      this.$emit('onChildInit', 'Edit Sales')
      this.get_all()
      this.get(this.$route.params.id)
    },
    watch: {
      sales (nextState, prevState) {
        if(nextState !== prevState){
          if(nextState.sales_accounts){
            this.value_multiple_sales = nextState.sales_accounts.map(item => item.account_id)
          }
          return
        }
      },
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({valueProp:item.id, label:item.account_name})
            })
          }
          return
        }
      },
    }
  }
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
