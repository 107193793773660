<template>
  <div class="row">
    <div class="col-sm-12">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="account_id">Account:</label>
            <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"/>
          </div>
          <div class="form-group col-sm-12 col-md-6 required">
            <label for="from_date">From Date:</label>
            <input class="form-control" required type="date" v-model="from_date">
          </div>
          <div class="form-group col-sm-12 col-md-6 required">
            <label for="to_date">To Date:</label>
            <input class="form-control" required type="date" v-model="to_date">
          </div>
          <div class="form-group col-sm-12">
            <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-12">
      <Datatable v-bind:entries="trip_data" :columns="columns" :title="'Spdas'"/>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import { getCapability } from '../_helpers'
  import moment from 'moment'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
      ...mapState('trips', ['trips', 'onRequest']),
      ...mapState('accounts', ['accounts']),
      ...mapState('users', ['user']),
      columns(){
        let data = this.first_table
        if(getCapability('sub_routes', this.user)){
          data.push({name: 'sub_route', text: 'Sub Route',
            raw: {
              type: 'Sub',
              value: 'sub_route_name'
            }
          })
        }
        data = data.concat(this.second_table)
        data.push({name: 'action', text: 'Action',
          raw: {
            type: 'Action',
            fields: [
              getCapability('edit_spda', this.user) ? {event: 'edit_spda', text: 'Edit', method: 'get', class: 'btn btn-primary'} : {},
              getCapability('delete_spda', this.user) ? {event: 'trips/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},
              {event: 'print_spda', text: 'Print', method: 'print-spda', class: 'btn btn-info ml-1'},
              {event: 'playback_trip', text: 'Playback', method: 'get', class: 'btn btn-secondary ml-1', check: {
                  name: 'status', value: 3
              }},
              getCapability('adjust_spda', this.user) ? {event: 'adjust_spda', text: 'Adjust', method: 'get', class: 'btn btn-warning ml-1'} : {},
            ]
          }
        })
        return data
      }
    },
    data(){
      return {
        account_id: null,
        accountOptions: [],
        trip_data: [],
        from_date: moment().subtract(1, 'days').format("YYYY-MM-DD"),
        to_date: moment().add(1, 'days').format("YYYY-MM-DD"),
        first_table :[
          {name: 'trip_date', text: 'Document Date', order: 'desc', raw:{type:'Moment_date'}},
          {name: 'trip_code', text: 'No SPDA'},
          {name: 'vehicle', text: 'Vehicle No',
            raw: {
              type: 'Sub',
              value: 'vehicle_no'
            }
          },
          {name: 'vehicle', text: 'Vehicle Code',
            raw: {
              type: 'Sub',
              value: 'vehicle_code'
            }
          },
          {name: 'driver', text: 'Driver',
            raw: {
              type: 'Sub',
              value: 'name'
            }
          },
          {name: 'route', text: 'Route',
            raw: {
              type: 'Sub',
              value: 'route_name'
            }
          },
        ],
        second_table:[
          {name: 'account', text: 'Account',
            raw: {
              type: 'Sub',
              value: 'account_name'
            }
          },
          {name: 'status', text: 'Status',
            raw: {
              type: 'Text',
              fields: [
                {value: '0', text: 'Draft'},
                {value: '1', text: 'Started'},
                {value: '2', text: 'Completed One SPDA'},
                {value: '3', text: 'Completed'},
                {value: '4', text: 'Cancelled'},
              ]
            }
          },
          {name: 'time_duration', text: 'Duration'},
          {name: 'distance', text: 'Distance (km)', class: 'text-right',
            raw :{type: 'decimal_number', decimal: 2}},
          {name: 'total_penumpang', text: 'Total Penumpang', class: 'text-right',
              raw: {
                type: 'format_number'
              }
            },
          {name: 'revenue1', text: 'Revenue Rit 1', class: 'text-right',
              raw: {
                type: 'format_number'
              }
            },
          {name: 'total_revenue', text: 'Total Revenue', class: 'text-right',
              raw: {
                type: 'format_number'
              }
            },
          {name: 'created_at', text: 'Created', raw:{type:'Moment_UTC'}},
        ]
      }
    },
    components:{
      Datatable,
      Select2,
    },
    methods: {
      ...mapActions('trips', ['get_trip_by_account']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {account_id, from_date, to_date} = this
        let day = moment(from_date).diff(moment(to_date), 'days')
        if(day > 0){
          this.error('Please Input Correct Dates.')
          return;
        }
        this.get_trip_by_account({account_id, from_date, to_date})
      },
    },
    created() {
      this.$emit('onChildInit', 'SPDA', getCapability('add_spda', this.user) ? '/spdas/add' : '')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
            if(this.accountOptions.length > 0){
              this.account_id = this.accountOptions[0].id
              this.handleSubmit()
            }
          }
          return
        }
      },
      trips(nextState, prevState){
        if(nextState !== prevState){
          this.trip_data = []
          if(nextState.length > 0){
            this.trip_data = nextState.map(item => {
              let item2 = item
              if(!item2.distance || item2.distance == null){
                item2.distance = item.status == 3 && item.route ? item.route.route_distance : null
              }
              item2.time_duration = null
              if(item.status == 3 && item.start && item.finish){
                let duration = moment.duration(moment(item.finish).diff(item.start))
                let hours = parseInt(duration.asHours());
                let minutes = parseInt(duration.asMinutes()) % 60;
                let s = parseInt(duration.asSeconds()+ 1)%60 ;
                item2.time_duration = (hours > 0 ? hours + "h " : "") + (minutes > 0 ? minutes + "min " : "") + (s > 0 ? s + "s" : "")
              }
              return item2
            })
          }
          return
        }
      },
    }
  }
</script>
