<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div v-if="sim_card.account" class="form-group col-sm-12">
          <label for="parent_id">Account:</label>
          <input class="form-control" disabled name="account_id" type="text" v-model="sim_card.account.account_name">
      </div>
      <div class="form-group col-sm-12 required">
          <label for="name">GSM No:</label>
          <input class="form-control" required="" name="gsm_no" type="text" v-model="sim_card.gsm_no">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="sim_card_code">Operator:</label>
        <select class="form-control" name="status" v-model="sim_card.operator" required>
          <option value="Telkomsel" style="color:black;">Telkomsel</option>
          <option value="XL Axiata" style="color:black;">XL Axiata</option>
          <option value="3 Tri" style="color:black;">3 Tri</option>
          <option value="Indosat Ooredoo" style="color:black;">Indosat Ooredoo</option>
          <option value="Smartfren" style="color:black;">Smartfren</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <label for="name">MSIDN:</label>
        <input class="form-control" name="msidn" type="text" v-model="sim_card.msidn">
      </div>
      <div class="form-group col-sm-12">
        <label for="name">IMSI No:</label>
        <input class="form-control" name="imsi" type="text" v-model="sim_card.imsi">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model.number="sim_card.status">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/sim_cards">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    computed: {
        ...mapState('sim_cards', ['sim_card', 'onRequest']),
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.edit(this.sim_card)
      },
      ...mapActions('sim_cards', ['edit', 'get']),
    },
    created() {
      this.$emit('onChildInit', 'Edit Sim Card')
      this.get(this.$route.params.id)
    }
  }
</script>
